import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ServicePlanIDs } from 'src/app/shared/enums/enums';
import { ClientDetails } from 'src/app/shared/interfaces/Client/ClientDetails';
import { ClientListData, ClientsListResponse } from 'src/app/shared/interfaces/Client/ClientsListResponse';
import { AuthService } from 'src/app/shared/services';
import { ClientModuleService } from 'src/app/shared/services/client-module.service';

@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss'],
})
export class ClientHeaderComponent implements OnInit, OnDestroy {
  clients: ClientListData[] = [];
  isLoading: boolean = false;
  currentUserEmail: string;
  selectedClient: number;
  selectedClientDetails: ClientDetails;
  currentRouteIndex: number = -1;

  servicePlanIDs = ServicePlanIDs;

  private onDestroySubjects: Subject<void> = new Subject();

  constructor(
    private modalService: NgbModal,
    private _clientModuleService: ClientModuleService,
    private _authService: AuthService,
    private _router: Router,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.currentUserEmail = userInfo.emailId;
    } else {
      this._authService.logout();
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._clientModuleService.clientObservable.pipe(takeUntil(this.onDestroySubjects)).subscribe((d) => {
      this.selectedClient = d.clientId;
      this.selectedClientDetails = { ...d };
    });

    this.subscribeClientRoutes();
    this.getClientList();
  }

  ngOnDestroy() {
    this.onDestroySubjects.next();
    this.onDestroySubjects.complete();
    this.onDestroySubjects.unsubscribe();
  }

  subscribeClientRoutes() {
    this._router.events
      .pipe(
        takeUntil(this.onDestroySubjects),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: any) => {
        this.currentRouteIndex = event.url.includes('invoice') ? 1 : -1;
      });
  }

  getClientList() {
    this._clientModuleService.getClientList(this.currentUserEmail).subscribe({
      next: (res: ClientsListResponse) => {
        if (res.result && res.result.length > 0) {
          const tempLastId = this._authService.get('lCI');
          if (tempLastId !== null && parseInt(tempLastId) > 0) {
            this.selectedClient = parseInt(tempLastId);
            const cName = res.result.find((cli) => {
              return cli.clientId === this.selectedClient;
            });
            if (cName) {
              this.selectedClientDetails = { ...cName };
              const client: ClientDetails = {
                clientId: this.selectedClient,
                clientName: cName.clientName,
                servicePlanId: cName.servicePlanId,
                servicePlanName: cName.servicePlanName,
                accruedFreeHrs: cName.accruedFreeHrs,
                remainingHrs: cName.remainingHrs,
              };
              this._clientModuleService.setClientId(client);
              this._authService.set('lCI', client.clientId.toString());
            }
          } else {
            const clientDetails = res.result[0];
            const client: ClientDetails = {
              clientId: clientDetails.clientId,
              clientName: clientDetails.clientName,
              servicePlanId: clientDetails.servicePlanId,
              servicePlanName: clientDetails.servicePlanName,
              accruedFreeHrs: clientDetails.accruedFreeHrs,
              remainingHrs: clientDetails.remainingHrs,
            };
            this._authService.set('lCI', client.clientId.toString());
            if (!this.selectedClientDetails.clientId || this.selectedClientDetails.clientId === 0) {
              this.selectedClient = client.clientId;
              this.selectedClientDetails = { ...client };
              this._clientModuleService.setClientId(client);
            }
          }
        } else {
          this._authService.logout();
        }
        this.clients = res.result;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  clientChanged() {
    if (this.selectedClient) {
      const tempClient = this.clients.filter((cli) => {
        return cli.clientId == this.selectedClient;
      });
      this.selectedClientDetails = { ...tempClient[0] };
      if (tempClient && tempClient.length > 0) {
        const client: ClientDetails = {
          clientId: tempClient[0].clientId,
          clientName: tempClient[0].clientName,
          servicePlanId: tempClient[0].servicePlanId,
          servicePlanName: tempClient[0].servicePlanName,
          accruedFreeHrs: tempClient[0].accruedFreeHrs,
          remainingHrs: tempClient[0].remainingHrs,
        };
        this._authService.set('lCI', client.clientId.toString());
        this._clientModuleService.setClientId(client);
      }
    } else {
      const client: ClientDetails = {
        clientId: 0,
        clientName: '',
        servicePlanId: 0,
        servicePlanName: '',
        remainingHrs: 0,
        accruedFreeHrs: 0,
      };
      this._authService.set('lastClientId', client.clientId.toString());
      this._clientModuleService.setClientId(client);
    }
  }

  routeInvoices() {
    this._router.navigate(['client', 'dashboard', 'invoice']);
  }
}
