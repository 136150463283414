import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-client-root',
  templateUrl: './client-root.component.html',
})
export class ClientRootComponent {
  constructor(
    private _authService: AuthService,
    private router: Router,
  ) {
    const token = this._authService.get('accessToken');
    const isClient = this._authService.get('isClient');
    if (isClient != '' && isClient == 'false' && token) {
      this.router.navigate(['admin']);
    }
  }
}
