<div class="side-menu">
  <div class="side-menu-logo">
    <img src="../../assets/images/HarperJames-Logo.svg" alt="" />
  </div>
  <div class="side-menu-wrapper">
    <app-vertical-navigation [menuItems]="menuItems" [role]="roleType"></app-vertical-navigation>
  </div>
  <div class="profile-info" (click)="openProfileEdit()">
    <div class="profile-info-pic">
      <img
        *ngIf="profileImageURL; else defaultAvatar"
        alt=""
        src="{{ profileImageURL }}"
        style="height: inherit; width: inherit; border-radius: 8px"
        onerror='this.onerror = null; this.src="../../assets/images/person-circle.svg"'
      />
      <ng-template #defaultAvatar>
        <img
          class="default-user-avatar"
          src="../../assets/images/person-circle.svg"
          alt=""
          style="height: inherit; width: inherit; border-radius: 8px"
        />
      </ng-template>
    </div>
    <div class="profile-info-wrap">
      <h4>{{ currentUserDetails?.fullName }}</h4>
      <span>{{ currentUserDetails?.roleName }}</span>
    </div>
  </div>
</div>
<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
