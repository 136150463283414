<header>
  <div class="hours-calc-info no-right-header">
    <div class="client-header">
      <div class="client-header-information">
        <div class="client-header-left">
          <div class="content"><svg-icon name="call-icon" class="icon"></svg-icon> <span>0808 168 6227</span></div>
          <div class="content">
            <svg-icon name="mail-icon-blue" class="icon"></svg-icon> <span>clientservices&#64;harperjames.co.uk</span>
          </div>
        </div>

        <div class="client-header-info">
          <div
            (click)="routeInvoices()"
            [ngClass]="{ active: currentRouteIndex === 1 }"
            class="hours-calc-info-wrap service-plan-section"
          >
            <div class="hours-calc-info-img"><svg-icon name="service-plan-icon" class="icon"></svg-icon></div>
            <div class="hours-calc-info-content">
              <span>Client's Plan</span>
              <h3>{{ selectedClientDetails?.servicePlanName || '' }}</h3>
            </div>
          </div>
          <div
            *ngIf="
              !(
                selectedClientDetails.servicePlanId === servicePlanIDs.city ||
                selectedClientDetails.servicePlanId === servicePlanIDs.extendedEnterprise
              )
            "
            [ngClass]="{ active: currentRouteIndex === 2 }"
            class="hours-calc-info-wrap hours-section"
          >
            <div class="hours-calc-info-img"><svg-icon name="loading-time-icon" class="icon"></svg-icon></div>
            <div class="hours-calc-info-content">
              <div *ngIf="selectedClientDetails.servicePlanId === servicePlanIDs.enterprise">
                <span>Accrued Hours</span>
                <h3>{{ selectedClientDetails?.accruedFreeHrs || 0 }}</h3>
              </div>
              <div
                *ngIf="
                  selectedClientDetails.servicePlanId === servicePlanIDs.onDemand ||
                  selectedClientDetails.servicePlanId === servicePlanIDs.extendedOnDemand
                "
              >
                <span>Remaining Hours</span>
                <h3>{{ selectedClientDetails?.remainingHrs || 0 }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="client-header-right">
        <div class="ng-select--filled with-left-icon">
          <div class="left-icon">
            <svg-icon name="client-icon" style="font-size: 18px; width: 15px; color: #616161" class="icon"></svg-icon>
          </div>
          <ng-select
            placeholder="Select"
            [items]="clients"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="clientName"
            bindValue="clientId"
            [(ngModel)]="selectedClient"
            (change)="clientChanged()"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
