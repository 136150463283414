import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Permissions } from 'src/app/shared/enums/enums';
import {
  Permission,
  RoleDetailsByRoleIdResponse,
} from 'src/app/shared/interfaces/Admin/Role/RoleDetailsByRoleIdResponse';
import { User, UserDetailsByUserIdResponse } from 'src/app/shared/interfaces/Auth/UserDetailsByUserIdResponse';
import { ClientMenuItems } from 'src/app/shared/menu-items/client-menu-items';
import { AuthService, LoginService, RoleService, UserService } from 'src/app/shared/services';
import { ClientModuleService } from 'src/app/shared/services/client-module.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
@Component({
  selector: 'app-client-sidemenu',
  templateUrl: './client-sidemenu.component.html',
  styleUrls: ['./client-sidemenu.component.scss'],
})
export class ClientSidemenuComponent implements OnInit {
  firstRoute: string | null;
  isMenuLoading: boolean = true;
  routeToBeLoaded: string = '';
  roleId: number;
  roleType: string = '';
  roleTypeId: number;
  permissions: Permission[];
  tempmenuItems: any;
  profileImageURL: string | undefined = '';
  tempPermission: boolean = false;
  nonUser: string = 'Non-User';
  currentUserId: number;
  currentUserDetails: User;

  selectedClient: number;
  menuItems: any[] = [];
  isLoading: boolean = false;
  isFeedbackCanCreateUpdate = false;

  private _destroyRef = inject(DestroyRef);

  constructor(
    public menu: ClientMenuItems,
    private _authService: AuthService,
    private router: Router,
    private _userService: UserService,
    private _clientModuleService: ClientModuleService,
    private _loginService: LoginService,
    private _roleService: RoleService,
    private _modalService: NgbModal,
    private _toastrService: ToastrService,
    private _utils: UtilsService,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.currentUserId = userInfo.userId;
      this.roleType = userInfo.role;
      this.roleId = userInfo.roleId;
      this.roleTypeId = userInfo.roleTypeId;
      this.getUserDetails(this.currentUserId);
    } else {
      this._authService.logout();
    }
    this.getUserProfile(() => {});
    this.getPermissionList(() => {
      this.permissionCheck(() => {
        this.loopPermission(() => {
          this.menuItems = this.tempmenuItems;
          this.isMenuLoading = false;
          if (this.tempPermission) {
            this.router.navigate([this.routeToBeLoaded]);
          } else if (this.firstRoute) {
            this.router.navigate([this.firstRoute], { replaceUrl: true });
          } else this.router.navigate(['client/dashboard/not-found']);
        });
      });
    });
  }

  ngOnInit(): void {
    this._loginService.profilePictureObservable.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((d) => {
      this.profileImageURL = d;
    });
    this._loginService.userNameObservable.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((d) => {
      d && (this.currentUserDetails.fullName = d);
    });
  }

  getPermissionList(callback: any) {
    this._roleService.getRolePermissionByRoleId(this.roleId).subscribe((res: RoleDetailsByRoleIdResponse) => {
      this.permissions = res.result.permission;
      this.isFeedbackCanCreateUpdate = this._utils.getPermissionByName(
        this.permissions,
        Permissions.Feedback,
      ).canCreateUpdate;
      this._roleService.setUpdatedData(this.permissions);
      callback();
    });
  }

  permissionCheck(callback: any) {
    this.tempmenuItems = this.menu.getMenuitem();
    callback();
  }

  checkRolePermission(menu: any, flags: boolean[] = []) {
    if (!menu.children && this.permissions) {
      return this.setRolesForMenu(menu, flags);
    }
    menu?.children?.forEach((child: any) => {
      this.checkRolePermission(child, flags);
    });
    if (menu.label !== 'Logout' && menu.label !== 'editprofile' && menu.roles) menu.roles.push(this.nonUser);
    if (flags?.includes(true) && menu.roles) menu.roles.push(this.roleType);
  }

  setRolesForMenu(menu: any, flags: boolean[] = []) {
    const temp = this.permissions.find((per) => per.featureName === menu.label && per.canView);
    if (temp) {
      if (this.router.url.search('client') && this.router.url.localeCompare(menu.route) === 0) {
        this.tempPermission = true;
        this.routeToBeLoaded = menu.route;
      } else if (this.router.url.includes('editprofile')) {
        this.tempPermission = true;
        this.routeToBeLoaded = this.router.url;
      }
      if (!this.firstRoute && temp.canView) this.firstRoute = menu.route;
      menu.roles.push(this.roleType);
      flags?.push(true);
      return;
    } else if (menu.roles) {
      menu.roles.push(this.nonUser);
    }
    flags?.push(false);
  }

  loopPermission(callback: any) {
    this.tempmenuItems.forEach((row: any, index: number) => {
      if (row.label !== 'Logout' && row.label !== 'editprofile') row.roles = [];
      this.checkRolePermission(row);
      if (index === this.tempmenuItems.length - 1) callback();
    });
  }

  getUserDetails(userId: number) {
    this._loginService.getUserByID(userId).subscribe((res: UserDetailsByUserIdResponse) => {
      this.roleType = res.result.roleName;
      this.currentUserDetails = res.result;
    });
  }

  openProfileEdit() {
    this.router.navigate(['client', 'dashboard', 'editprofile']);
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.profileImageURL = reader.result?.toString();
        if (this.profileImageURL) this._clientModuleService.setClientProfilePicture(this.profileImageURL);
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getUserProfile(callback: any) {
    this._userService.getUserProfileImage(this.currentUserId).subscribe({
      next: (response: Blob) => {
        this.createImageFromBlob(response);
        callback();
      },
      error: () => {
        callback();
      },
    });
  }
}
